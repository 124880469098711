<template>
  <div>
    <template v-if="!loading">
      <v-container fluid>
        <v-row id="filters" v-if="showFilters && images.length > 0" no-gutters>
          <v-col :cols="12">
            <template v-if="deleting">
              <v-btn @click="markAllForDeletion()"
                     outlined
                     color="primary"
                     :disabled="deletingScreenshots"
                     :block="$store.state.isMobile"
                     class="mb-1 mr-1"
                     style="float: right;"
              >
                <v-icon>select_all</v-icon>
                Select All
              </v-btn>

              <v-btn @click="deleteImages()"
                     color="warning"
                     outlined
                     :disabled="deletingScreenshots"
                     :loading="deletingScreenshots"
                     :block="$store.state.isMobile"
                     class="mb-1 mr-1"
                     style="float: right;"
              >
                <v-icon>delete</v-icon>
                Delete
              </v-btn>

              <v-btn @click="cancelDeleting()"
                     color="secondary"
                     outlined
                     :disabled="deletingScreenshots"
                     :block="$store.state.isMobile"
                     class="mb-2 mr-1"
                     style="float: right;"
              >
                Cancel
              </v-btn>

              <div class="caption mb-2" style="font-style: italic">Screenshot deletion can be disabled by locking your account from the <router-link to="account">account</router-link> page.  If you delete screenshots, an email will be sent to your report recipients with the meta data from the screenshots that were deleted indicating that you deleted them.</div>
            </template>

            <template v-if="!deleting">

              <v-select v-if="showRiskFilter"
                        class="ml-4"
                        :class="{ 'all-the-width': $store.state.isMobile}"
                        solo
                        prepend-icon="warning"
                        multiple
                        style="float: right;"
                        v-model="selectedRiskFilters"
                        :items="riskLabelsByValue"
                        label="Risk Filter"
                        item-value="value"
                        item-text="text"
                        :error="highRiskMessage.length > 0"
                        :error-messages="highRiskMessage"
              >
              </v-select>

              <v-select
                      solo
                      class="ml-4"
                      :class="{ 'all-the-width': $store.state.isMobile}"
                      prepend-icon="smartphone"
                      multiple
                      style="float: right;"
                      v-model="selectedDeviceFilters"
                      :items="deviceFilters"
                      label="Device Filter"
                      item-value="deviceId"
                      item-text="deviceName"
              >
              </v-select>
            </template>

          </v-col>
        </v-row>
        <v-row v-if="images.length > 0" style="width: 100%; height: 100%;" no-gutters>
          <v-col :cols="12">

            <!--          <v-virtual-scroll-->
            <!--            :items="rows"-->
            <!--            :item-height="rowHeight"-->
            <!--            :bench="1"-->
            <!--            :height="500"-->
            <!--          >-->
            <!--            <template v-slot="{ index, item }">-->
            <template v-for="(item, index) of rows" >
              <div class="img-row d-flex mb-2 align-center" :key="index">
                <template v-for="imageItem in item.items">
                  <div style="position: relative;" class="mx-auto" :key="imageItem.fileKey">
                    <encrypted-thumbnail
                      :show-meta-data="showMetaData"
                      @on-click="viewImage(imageItem.fileKey)"
                      :image="imageItem"
                      :width="calculatedWidth"
                      :device-id="getReversedDeviceId(imageItem.deviceId)"
                      :account-id="$store.state.account.account.accountId"
                    >
                    </encrypted-thumbnail>
                    <div v-if="deleting"
                         :style="`z-index: 3; position: absolute; bottom: 10px; right: 10px;`"
                    >
                      <div
                        style="height: 40px; width: 40px; background-color: white; border-radius: 50%; display: inline-block; position: absolute; bottom: 0; right: 0; border: 1px solid #FFC107;"
                        v-if="canDelete && deleting && !$store.getters.isAccountLocked && reverseString(imageItem.deviceId) !== $store.state.account.deviceId">
                        <v-checkbox
                          color="accent"
                          class="ma-0"
                          style="padding: 7px 7px 7px 7px;"
                          v-model="imageItem.markedForDeletion"
                        >
                        </v-checkbox>
                      </div>

                    </div>
                  </div>
                </template>
              </div>
            </template>
            <!--            </template>-->
            <!--          </v-virtual-scroll>-->
          </v-col>
        </v-row>
      </v-container>
    </template>

    <loading v-if="loading"
             :message="'Loading images...'">
    </loading>

<!--    <v-btn-->
<!--      small-->
<!--      color="accent"-->
<!--      fixed-->
<!--      bottom-->
<!--      right-->
<!--      fab-->
<!--    >-->
<!--      <v-icon>menu</v-icon>-->
<!--    </v-btn>-->

    <v-speed-dial
      :open-on-hover="false"
      direction="top"
      transition="slide-y-reverse-transition"
      fixed
      bottom
      right
    >
      <template v-slot:activator>
        <v-btn
          color="accent"
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        @click="refreshImages()"
        color="primary"
      >
        <v-icon>refresh</v-icon>
      </v-btn>

      <v-btn
        @click="toggleMetaData"
        fab
      >
        <v-icon>{{showMetaData ? 'subtitles_off' : 'subtitles'}}</v-icon>
      </v-btn>

      <v-btn v-if="canDelete && !$store.getters.isAccountLocked"
             color="error"
             @click="deleting = !deleting"
             fab
      >
        <v-icon>delete</v-icon>
      </v-btn>

    </v-speed-dial>

    <photo-gallery
      v-if="showGallery"
      :images="filteredImages"
      :open-index="imageClicked"
      :account-id="$store.state.account ? $store.state.account.account.accountId : null"
      :device-id="filteredImages[imageClicked] ? reverseString(filteredImages[imageClicked].deviceId) : null"
      :visible="showGallery"
      @on-close="closeGallery"
    />
  </div>
</template>

<script>
  import Loading from "./Loading"
  import PhotoGallery from "./images/PhotoGallery";

  import {
    highRisk,
    unknownRisk,
    riskLabelsByValue} from "../risk"
  import ImageCache from "../services/ImageCache";
  import Utils from "../Utils";
  import EncryptedThumbnail from "./e2ee/EncryptedThumbnail";

  let reversedDeviceIds = {}

  let ranOnce = false

  export default {
    name: "ScreenshotViewer",
    components: {EncryptedThumbnail, Loading, PhotoGallery},
    data () {
      return {
        showOwnScreenshots: null,
        speedDial: null,
        showMetaData: true,
        selectedDeviceFilters: [],
        selectedRiskFilters: [],
        riskLabelsByValue: riskLabelsByValue,
        highRisk: highRisk,
        deleting: false,
        deletingScreenshots: false,
        showGallery: false,
        imageClicked: 0,
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight
      }
    },
    props: {
      images: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      showImages: {
        type: Boolean,
        required: true
      },
      showFilters: {
        type: Boolean,
        required: false
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      handleViewOwnScreenshotsFlag() {
        if(this.$store.state.showOwnScreenshots === false || this.$store.state.showOwnScreenshots === "false") {

          let deviceObjs = this.deviceFilters.filter(x => {
            console.log(`deviceFilters: ${x.deviceId} === ${Utils.reverseString(this.currentDevice)} ${x.deviceId === Utils.reverseString(this.currentDevice)}`)
            return x.deviceId !== Utils.reverseString(this.currentDevice)
          })

          let filters = []

          for(let dobjs of deviceObjs) {
            filters.push(dobjs.deviceId)
          }

          this.selectedDeviceFilters = filters
        }
      },
      toggleMetaData() {
        this.showMetaData = !this.showMetaData
        console.log(`this.showMetaData = ${this.showMetaData}`)
      },
      reverseString(str) {
        return Utils.reverseString(str)
      },
      refreshImages() {
        console.log('emitting on-refresh-images')
        this.$emit('on-refresh-images')
      },
      onResize() {
        this.windowInnerWidth = window.innerWidth
        this.windowInnerHeight = window.innerHeight
        this.$forceUpdate() // ghetto hack. Without this the check box isn't updating unless you move the mouse outside the image.
      },
      getReversedDeviceId(deviceId) {
        if(!reversedDeviceIds[deviceId]) {
          reversedDeviceIds[deviceId] = Utils.reverseString(deviceId)
        }
        return reversedDeviceIds[deviceId]
      },
      closeGallery() {
        this.showGallery = false
      },
      cancelDeleting() {
        this.deleting = false;
        for(let image of this.images) {
          image.markedForDeletion = false
        }
      },
      markAllForDeletion() {
        let correctedDeviceId = Utils.reverseString(this.currentDevice)
        for(let image of this.filteredImages) {
          if(image.deviceId !== correctedDeviceId) {
            image.markedForDeletion = true
          }
        }

        this.$forceUpdate() // ghetto hack. Without this the check box isn't updating unless you move the mouse outside the image.
      },
      async deleteImages() {

        let maxImagesPerChunk = 100
        let chunksOfImagesToDelete = []
        let currentChunk = 0

        let count = 0
        for(let image of this.filteredImages) {
          if(image.markedForDeletion) {

            if(!chunksOfImagesToDelete[currentChunk]) {
              chunksOfImagesToDelete.push([])
            }

            chunksOfImagesToDelete[currentChunk].push({
              deviceId: image.deviceId,
              fileKey: image.fileKey,
              takenAt: image.takenAt,
              takenAtString: new Date(image.takenAt).toString()
            })
            count++

            if(chunksOfImagesToDelete[currentChunk].length >= maxImagesPerChunk - 1) {
              console.log('increasing chunk')
              currentChunk++
            }
          }
        }

        if(count === 0) {
          this.$swal('no images to delete')
          return
        }

        let result = await this.$swal(
            {
              title: 'Delete confirmation',
              text: `Are you sure you'd like to permanently delete ${count} screenshot${count > 1 ? 's' : ''}?  Emails will be sent to all report recipients regarding the deletion.`,
              showCancelButton: true
            }
        )

        if(result.isConfirmed){
          this.deletingScreenshots = true
          let promises = []

          for(let chunk of chunksOfImagesToDelete) {
            let request = {
              images: chunk
            }

            promises.push(this.api.deleteImages(request))
          }

          try {
            await Promise.all(promises)
          } catch (error) {
            console.log(error)
            this.$swal('error deleting images')
          }

          this.cancelDeleting()
          this.$emit('on-images-deleted')
          this.deletingScreenshots = false;
        }

        console.log('delete images')
        console.log(chunksOfImagesToDelete)
      },
      calculateImageHeight(width, ratio) {
        return width * ratio;
      },
      viewImage(fileKey) {

        let index = -1
        for(let c = 0; c < this.filteredImages.length; c++) {
          if(this.filteredImages[c].fileKey === fileKey) {
            index = c;
            break;
          }
        }

        if(index !== -1) {
          console.log('view image ' + index)
          this.showGallery = false
          this.$nextTick(() => {
            this.showGallery = true
            this.imageClicked = index
          })
        }
      }
    },
    watch: {
      images: function (newValue) {
        if(!ranOnce && newValue && newValue.length > 0) {
          this.handleViewOwnScreenshotsFlag()
          ranOnce = true
        }
      }
    },
    computed: {
      currentDevice() {
        if(this.$route.query.deviceId) {
          let currentDevice = decodeURIComponent(this.$route.query.deviceId).split('').reverse().join('')
          localStorage.setItem('currentDevice', currentDevice)
          return currentDevice
        } else {
          let currentDevice = localStorage.getItem('currentDevice')
          return currentDevice
        }
      },
      calculatedWidth() {

        // let padding = 4
        // let horiztonalPaddingPerImage = padding * 2
        //
        // let ipr = this.imagesPerRow
        //
        // let totalHorizontalPaddingPerRow = ipr * horiztonalPaddingPerImage
        //
        // let width = ((this.windowInnerWidth - totalHorizontalPaddingPerRow) / ipr)
        let rowMargin = 10
        let offset = 16
        let width = ((this.windowInnerWidth - offset - this.imagesPerRow * rowMargin) / this.imagesPerRow)

        return width
      },
      imagesPerRow() {
        //no longer tied to a grid, so we can return however many we want.
        if(this.windowInnerWidth <= 300) {
          return 2
        }
        else if(this.windowInnerWidth < 600) {
          return 3
        } else if(this.windowInnerWidth < 800) {
          return 4
        } else if(this.windowInnerWidth < 1000) {
          return 5
        } else if(this.windowInnerWidth < 1250) {
          return 6
        } else if(this.windowInnerWidth < 1500) {
          return 8
        } else if(this.windowInnerWidth < 1800) {
          return 10
        } else if(this.windowInnerWidth < 2000) {
          return 10
        } else {
          return 12
        }

        //lg2 sm3 xs4

        // // if(this.windowInnerWidth < 400) {
        // //   return 2
        // // }
        // // else
        // if(this.windowInnerWidth < 600) {
        //   //xs
        //   return 3
        // } else if(this.windowInnerWidth < 960) {
        //   //sm
        //   return 4
        // } else if(this.windowInnerWidth < 1264) {
        //   //md
        //   return 6
        // } else if(this.windowInnerWidth < 1904) {
        //   //lg
        //   return 12
        // }
        // else {
        //   //xl
        //   // return 12
        //   return 12
        // }
      },
      showRiskFilter() {
        for(let i of this.images) {
          if(i.risk !== unknownRisk) {
            return true
          }
        }
        return false
      },
      highRiskMessage () {
        let count = 0
        for(let i of this.filteredImages) {
          if(i.risk === highRisk) {
            count++
          }
        }

        if(count > 0) {
          return [`${count} high risk screenshots`]
        }
        return []
      },
      deviceFilters () {
        if(!this.images || this.images.length === 0) {
          return []
        }

        let deviceFilters = {}
        this.images.forEach(imageRecord => {
          if(!deviceFilters[imageRecord.deviceId]) {
            deviceFilters[imageRecord.deviceId] = {
              deviceId: imageRecord.deviceId,
              deviceName: imageRecord.deviceName
            }
          }
        })

        return Object.values(deviceFilters)
      },
      imagesFilteredByRisk () {

        let images = this.images

        if(!this.selectedRiskFilters || this.selectedRiskFilters.length === 0) {
          return images
        }

        let riskFilterSet = new Set()
        for(let rf of this.selectedRiskFilters) {
          riskFilterSet.add(rf)
        }

        let filteredImages = []
        images.forEach(ir => {
          if(riskFilterSet.has(ir.risk)) {
            filteredImages.push(ir)
          }
        })

        return filteredImages
      },
      rows() {
        let ipr = this.imagesPerRow

        let ret = []
        let row = {
          id: '',
          items: []
        }
        let imagesPerRowCounter = 0
        for(let i of this.filteredImages) {
          row.items.push(i)
          row.id += i.fileKey
          imagesPerRowCounter++
          if(imagesPerRowCounter >= ipr) {
            imagesPerRowCounter = 0
            ret.push(Object.freeze(row))
            row = {
              id: '',
              items: []
            }
          }
        }

        if(row.items.length > 0) {
          ret.push(Object.freeze(row))
        }

        // if(this.startDay <= 14) {
        //   ret.push({
        //     loadMore: true
        //   })
        // }

        return ret
      },
      rowHeight () {
        if(!this.filteredImages) {
          return 52
        }

        let maxHeight = 0;
        for(let i of this.filteredImages) {
          let currentImageHeight = i.imageRatio * this.calculatedWidth
          if(currentImageHeight > maxHeight) {
            maxHeight = currentImageHeight
          }
        }

        return maxHeight
      },
      filteredImages () {
        if(!this.selectedDeviceFilters || this.selectedDeviceFilters.length === 0) {
          return this.imagesFilteredByRisk
        }

        let deviceIdSet = new Set()
        this.selectedDeviceFilters.forEach(df => {
          deviceIdSet.add(df)
        })

        let filteredImages = []
        for(let imageRecord of this.imagesFilteredByRisk) {
          if(deviceIdSet.has(imageRecord.deviceId)) {
            filteredImages.push(imageRecord)
          }
        }

        return filteredImages
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize)
      }
      ImageCache.destroy()
    }
  }
</script>

<style scoped>

  .all-the-width {
    width: 100%;
  }

</style>
