var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _vm.showFilters && _vm.images.length > 0
                  ? _c(
                      "v-row",
                      { attrs: { id: "filters", "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: 12 } },
                          [
                            _vm.deleting
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-1 mr-1",
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        outlined: "",
                                        color: "primary",
                                        disabled: _vm.deletingScreenshots,
                                        block: _vm.$store.state.isMobile
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.markAllForDeletion()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("select_all")]),
                                      _vm._v(
                                        "\n                Select All\n              "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-1 mr-1",
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        color: "warning",
                                        outlined: "",
                                        disabled: _vm.deletingScreenshots,
                                        loading: _vm.deletingScreenshots,
                                        block: _vm.$store.state.isMobile
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteImages()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("delete")]),
                                      _vm._v(
                                        "\n                Delete\n              "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-2 mr-1",
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        color: "secondary",
                                        outlined: "",
                                        disabled: _vm.deletingScreenshots,
                                        block: _vm.$store.state.isMobile
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelDeleting()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Cancel\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "caption mb-2",
                                      staticStyle: { "font-style": "italic" }
                                    },
                                    [
                                      _vm._v(
                                        "Screenshot deletion can be disabled by locking your account from the "
                                      ),
                                      _c(
                                        "router-link",
                                        { attrs: { to: "account" } },
                                        [_vm._v("account")]
                                      ),
                                      _vm._v(
                                        " page.  If you delete screenshots, an email will be sent to your report recipients with the meta data from the screenshots that were deleted indicating that you deleted them."
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            !_vm.deleting
                              ? [
                                  _vm.showRiskFilter
                                    ? _c("v-select", {
                                        staticClass: "ml-4",
                                        class: {
                                          "all-the-width":
                                            _vm.$store.state.isMobile
                                        },
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          solo: "",
                                          "prepend-icon": "warning",
                                          multiple: "",
                                          items: _vm.riskLabelsByValue,
                                          label: "Risk Filter",
                                          "item-value": "value",
                                          "item-text": "text",
                                          error: _vm.highRiskMessage.length > 0,
                                          "error-messages": _vm.highRiskMessage
                                        },
                                        model: {
                                          value: _vm.selectedRiskFilters,
                                          callback: function($$v) {
                                            _vm.selectedRiskFilters = $$v
                                          },
                                          expression: "selectedRiskFilters"
                                        }
                                      })
                                    : _vm._e(),
                                  _c("v-select", {
                                    staticClass: "ml-4",
                                    class: {
                                      "all-the-width": _vm.$store.state.isMobile
                                    },
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": "smartphone",
                                      multiple: "",
                                      items: _vm.deviceFilters,
                                      label: "Device Filter",
                                      "item-value": "deviceId",
                                      "item-text": "deviceName"
                                    },
                                    model: {
                                      value: _vm.selectedDeviceFilters,
                                      callback: function($$v) {
                                        _vm.selectedDeviceFilters = $$v
                                      },
                                      expression: "selectedDeviceFilters"
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.images.length > 0
                  ? _c(
                      "v-row",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { "no-gutters": "" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: 12 } },
                          [
                            _vm._l(_vm.rows, function(item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "img-row d-flex mb-2 align-center"
                                  },
                                  [
                                    _vm._l(item.items, function(imageItem) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: imageItem.fileKey,
                                            staticClass: "mx-auto",
                                            staticStyle: {
                                              position: "relative"
                                            }
                                          },
                                          [
                                            _c("encrypted-thumbnail", {
                                              attrs: {
                                                "show-meta-data":
                                                  _vm.showMetaData,
                                                image: imageItem,
                                                width: _vm.calculatedWidth,
                                                "device-id": _vm.getReversedDeviceId(
                                                  imageItem.deviceId
                                                ),
                                                "account-id":
                                                  _vm.$store.state.account
                                                    .account.accountId
                                              },
                                              on: {
                                                "on-click": function($event) {
                                                  return _vm.viewImage(
                                                    imageItem.fileKey
                                                  )
                                                }
                                              }
                                            }),
                                            _vm.deleting
                                              ? _c(
                                                  "div",
                                                  {
                                                    style:
                                                      "z-index: 3; position: absolute; bottom: 10px; right: 10px;"
                                                  },
                                                  [
                                                    _vm.canDelete &&
                                                    _vm.deleting &&
                                                    !_vm.$store.getters
                                                      .isAccountLocked &&
                                                    _vm.reverseString(
                                                      imageItem.deviceId
                                                    ) !==
                                                      _vm.$store.state.account
                                                        .deviceId
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              height: "40px",
                                                              width: "40px",
                                                              "background-color":
                                                                "white",
                                                              "border-radius":
                                                                "50%",
                                                              display:
                                                                "inline-block",
                                                              position:
                                                                "absolute",
                                                              bottom: "0",
                                                              right: "0",
                                                              border:
                                                                "1px solid #FFC107"
                                                            }
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              staticClass:
                                                                "ma-0",
                                                              staticStyle: {
                                                                padding:
                                                                  "7px 7px 7px 7px"
                                                              },
                                                              attrs: {
                                                                color: "accent"
                                                              },
                                                              model: {
                                                                value:
                                                                  imageItem.markedForDeletion,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    imageItem,
                                                                    "markedForDeletion",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "imageItem.markedForDeletion"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.loading
        ? _c("loading", { attrs: { message: "Loading images..." } })
        : _vm._e(),
      _c(
        "v-speed-dial",
        {
          attrs: {
            "open-on-hover": false,
            direction: "top",
            transition: "slide-y-reverse-transition",
            fixed: "",
            bottom: "",
            right: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    { attrs: { color: "accent", fab: "" } },
                    [_c("v-icon", [_vm._v("menu")])],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.refreshImages()
                }
              }
            },
            [_c("v-icon", [_vm._v("refresh")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { fab: "" }, on: { click: _vm.toggleMetaData } },
            [
              _c("v-icon", [
                _vm._v(_vm._s(_vm.showMetaData ? "subtitles_off" : "subtitles"))
              ])
            ],
            1
          ),
          _vm.canDelete && !_vm.$store.getters.isAccountLocked
            ? _c(
                "v-btn",
                {
                  attrs: { color: "error", fab: "" },
                  on: {
                    click: function($event) {
                      _vm.deleting = !_vm.deleting
                    }
                  }
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.showGallery
        ? _c("photo-gallery", {
            attrs: {
              images: _vm.filteredImages,
              "open-index": _vm.imageClicked,
              "account-id": _vm.$store.state.account
                ? _vm.$store.state.account.account.accountId
                : null,
              "device-id": _vm.filteredImages[_vm.imageClicked]
                ? _vm.reverseString(
                    _vm.filteredImages[_vm.imageClicked].deviceId
                  )
                : null,
              visible: _vm.showGallery
            },
            on: { "on-close": _vm.closeGallery }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }