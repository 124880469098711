var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.getters.shouldBlockReportAccess
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center pt-3",
              staticStyle: { width: "100%" }
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "", "max-width": "500px" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        "\n        Your access to accountability reports is revoked due to your account lock.  You can regain access by unlocking your account from the "
                      ),
                      _c("router-link", { attrs: { to: "account" } }, [
                        _vm._v("accounts page")
                      ]),
                      _vm._v(".\n      ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : [
            _vm.showNoImagesMessage &&
            !_vm.loading &&
            !_vm.busy &&
            !_vm.loadingMore
              ? _c("div", { staticClass: "pa-3" }, [
                  _c(
                    "p",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm._v(
                        "No images found...  have you setup your devices to be monitored yet?  If not, then visit the "
                      ),
                      _c("router-link", { attrs: { to: "devices" } }, [
                        _vm._v("devices")
                      ]),
                      _vm._v(" page")
                    ],
                    1
                  ),
                  _c("p", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      "If the monitored device has been used and you expect there to be screenshots, please visit "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://" +
                            _vm.supportSite +
                            "/articles/misc/no-events-captured",
                          target: "_blank"
                        }
                      },
                      [_vm._v("this article")]
                    ),
                    _vm._v(
                      " for steps to restart the " +
                        _vm._s(_vm.appName) +
                        " app."
                    )
                  ])
                ])
              : _vm._e(),
            _c("screenshot-viewer", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.getters.termsAccepted,
                  expression: "$store.getters.termsAccepted"
                }
              ],
              attrs: {
                images: _vm.images,
                busy: _vm.busy,
                loading: _vm.loading,
                "show-images": !_vm.showNoImagesMessage,
                "show-filters": !_vm.showNoImagesMessage,
                "can-delete": true
              },
              on: {
                "on-images-deleted": _vm.refreshImages,
                "on-refresh-images": _vm.refreshImages
              }
            }),
            (!_vm.showNoImagesMessage &&
              !_vm.loading &&
              !_vm.busy &&
              _vm.endDay < 15) ||
            _vm.loadingMore
              ? _c(
                  "v-btn",
                  {
                    staticClass: "centered mb-5 text-center",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      loading: _vm.loadingMore
                    },
                    on: {
                      click: function($event) {
                        return _vm.incrementAndLoadImagesAwait(2)
                      }
                    }
                  },
                  [_vm._v("\n      Load More\n    ")]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }